import { Button, Form, Input, Typography } from "antd";
import {
  LoginTwoFactorsMutation,
  LoginTwoFactorsMutationVariables,
} from "@/graphql/output/graphql";

import ErrorFeedback from "@/components/error-feedback";
import { FC } from "react";
import Image from "next/image";
import { LOGIN_TWO_FACTORS } from "@/graphql/login";
import OtpInput from "@/components/otp-input";
import Trans from "next-translate/Trans";
import redirectToClient from "@/lib/redirectToClient";
import styles from "./TwoFactors.module.scss";
import { useAccessTokenCookie } from "@/hooks/useAccessTokenCookie";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

interface TwoFactorsProps {
  qrCode?: string | null;
  token: string;
  onBack: () => void;
}

const TwoFactors: FC<TwoFactorsProps> = ({ qrCode, token, onBack }) => {
  const router = useRouter();
  const [form] = Form.useForm();
  const { t } = useTranslation("two-factors");
  const setAccessTokenCookie = useAccessTokenCookie();

  const [loginTwoFactors, { loading, error }] = useMutation<
    LoginTwoFactorsMutation,
    LoginTwoFactorsMutationVariables
  >(LOGIN_TWO_FACTORS, {
    onError: () => {},
    onCompleted: (res) => {
      form.resetFields();

      const { redirect, tokens } = res.loginTwoFactors;

      if (redirect) {
        redirectToClient(redirect, tokens.access_token);
      } else {
        setAccessTokenCookie(tokens.access_token, tokens.expires_in);
      }
    },
  });

  const handleSubmitLoginTwoFactors = (
    values: LoginTwoFactorsMutationVariables
  ) => {
    loginTwoFactors({
      variables: {
        token,
        code: values.code,
        client: form.getFieldValue("client"),
      },
    });
  };

  return (
    <>
      {error && <ErrorFeedback asAlert error={error} />}

      <Form
        form={form}
        onFinish={handleSubmitLoginTwoFactors}
        initialValues={{
          client: router.query.client || process.env.NEXT_PUBLIC_OAUTH_CLIENT,
        }}
      >
        {qrCode ? (
          <>
            <Typography.Paragraph>
              <Trans
                i18nKey="two-factors:qrcode"
                components={[<b key={"bold"}></b>]}
              />
            </Typography.Paragraph>

            <Typography.Paragraph>
              <Trans
                i18nKey="two-factors:manual"
                components={[
                  <a
                    key="link"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.Link}
                    href="https://www.opiquad.it/area-clienti/accesso-my-opiquad-it/"
                  />,
                ]}
              />
            </Typography.Paragraph>

            <div className={styles.QrCode}>
              <Image width={200} height={200} alt="Qr Code" src={qrCode} />
            </div>
          </>
        ) : (
          <Typography.Paragraph>
            <Trans
              i18nKey="two-factors:recovery"
              components={[
                <a href="mailto:support-hub@opiquad.it" key="link" />,
              ]}
            />
          </Typography.Paragraph>
        )}

        <Form.Item name="client" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="code">
          <OtpInput length={6} />
        </Form.Item>

        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {t("confirm")}
        </Button>

        <Button
          block
          type="link"
          className="mt:2"
          onClick={() => {
            onBack();
            form.resetFields();
          }}
        >
          {t("back-to-login")}
        </Button>
      </Form>
    </>
  );
};

export default TwoFactors;
