import dayjs from "dayjs";
import { setCookie } from "cookies-next";

const setAccessTokenCookie = (token: string, expires_in: number) => {
  setCookie("access_token", token, {
    path: "/",
    expires: dayjs().add(expires_in, "seconds").toDate(),
    secure: true,
  });
};

export default setAccessTokenCookie;
