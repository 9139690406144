import React, { memo, useLayoutEffect, useRef } from "react";

import styles from "./SingleOTPInput.module.scss";

export interface SingleOTPInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
}

export function SingleOTPInputComponent({
  focus,
  ...rest
}: SingleOTPInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (inputRef.current && focus) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [focus]);

  return (
    <input {...rest} type="number" ref={inputRef} className={styles.Input} />
  );
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
