import React, { FC } from "react";

import { Spin } from "antd";
import classNames from "classnames";
import styles from "./Loader.module.scss";

interface LoaderProps {
  fullscreen?: boolean;
  className?: string;
}

const Loader: FC<LoaderProps> = ({ fullscreen = false, className = "" }) => {
  return (
    <div
      className={classNames(styles.Wrapper, className, {
        [styles.Fullscreen]: fullscreen,
      })}
    >
      <Spin />
    </div>
  );
};

export default Loader;
