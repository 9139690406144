
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { GetServerSideProps, NextPage } from "next";
import { LoginFromCookieMutation, LoginFromCookieMutationVariables, } from "@/graphql/output/graphql";
import { useEffect, useState } from "react";
import DocumentTitle from "@/components/document-title";
import { LOGIN_FROM_COOKIE } from "@/graphql/login";
import Loader from "@/components/loader";
import Login from "@/components/login/Login";
import TwoFactors from "@/components/two-factors/TwoFactors";
import { hasCookie } from "cookies-next";
import redirectToClient from "@/lib/redirectToClient";
import { useAccessTokenCookie } from "@/hooks/useAccessTokenCookie";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
interface LoginData {
    qrCode?: string | null;
    token: string;
}
const Home: NextPage = () => {
    const router = useRouter();
    const setAccessTokenCookie = useAccessTokenCookie();
    const [loginData, setLoginData] = useState<LoginData | null>(null);
    const [loginFromCookie, { loading, data }] = useMutation<LoginFromCookieMutation, LoginFromCookieMutationVariables>(LOGIN_FROM_COOKIE, {
        onError: () => { },
        variables: {
            client: (router.query.client ||
                process.env.NEXT_PUBLIC_OAUTH_CLIENT) as string,
        },
        onCompleted: (res) => {
            const { redirect, tokens } = res.loginFromCookie;
            if (redirect) {
                redirectToClient(redirect, tokens.access_token);
            }
            else {
                setAccessTokenCookie(tokens.access_token, tokens.expires_in);
            }
        },
    });
    useEffect(() => {
        if (router.isReady && !("logout" in router.query) && hasCookie("user_id")) {
            loginFromCookie();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);
    if (loading || data?.loginFromCookie) {
        return <Loader />;
    }
    return (<>
      <DocumentTitle title="Login"/>

      {!loginData ? (<Login onLoginCompleted={(token, qrCode) => setLoginData({ token, qrCode })}/>) : (<TwoFactors {...loginData} onBack={() => setLoginData(null)}/>)}
    </>);
};
const getServerSideProps: GetServerSideProps<any> = async (ctx) => {
    // Se l'utente ha già il token tra i cookie e non c'è il client
    // Allora sta richiedendo di accedere nuovamente all'SSO ed è già autenticato
    // Lo mando direttamente al profilo
    if (!("client" in ctx.query) && hasCookie("access_token", { req: ctx.req })) {
        return {
            redirect: {
                destination: "/profile",
                permanent: false,
            },
        };
    }
    return {
        props: {},
    };
};
export default Home;

    async function __Next_Translate__getServerSideProps__18fe780e78b__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18fe780e78b__ as getServerSideProps }
  