import { Button, Form, Input, InputRef } from "antd";
import { FC, useEffect, useRef } from "react";
import {
  LoginMutation,
  LoginMutationVariables,
  TwoFactorsAuth,
} from "@/graphql/output/graphql";

import ErrorFeedback from "@/components/error-feedback";
import { LOGIN } from "@/graphql/login";
import Link from "next/link";
import redirectToClient from "@/lib/redirectToClient";
import { useAccessTokenCookie } from "@/hooks/useAccessTokenCookie";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

interface LoginProps {
  onLoginCompleted: (token: string, qrCode?: string | null) => void;
}

const Login: FC<LoginProps> = ({ onLoginCompleted }) => {
  const router = useRouter();
  const [form] = Form.useForm();
  const { t } = useTranslation("login");
  const emailInput = useRef<InputRef | null>(null);
  const setAccessTokenCookie = useAccessTokenCookie();

  const [login, { loading, error }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LOGIN, {
    onError: () => {},
    onCompleted: (res) => {
      form.resetFields();

      if (res.login.__typename === "LoginSuccess") {
        const { redirect, tokens } = res.login;

        if (redirect) {
          redirectToClient(redirect, tokens.access_token);
        } else {
          setAccessTokenCookie(tokens.access_token, tokens.expires_in);
        }
      } else {
        const { qr_code, token } = res.login as TwoFactorsAuth;
        onLoginCompleted(token, qr_code);
      }
    },
  });

  const handleSubmitLogin = (values: LoginMutationVariables) => {
    login({
      variables: values,
    });
  };

  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus();
    }
  }, []);

  return (
    <>
      {error && <ErrorFeedback asAlert error={error} />}

      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmitLogin}
        initialValues={{
          client: router.query.client || process.env.NEXT_PUBLIC_OAUTH_CLIENT,
        }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: t("rules:required") },
            { type: "email", message: t("rules:email") },
          ]}
        >
          <Input ref={emailInput} size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: t("rules:required") }]}
          extra={
            <Link passHref href="/password/recovery">
              {t("password-dimenticata")}
            </Link>
          }
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item name="client" hidden>
          <Input />
        </Form.Item>
        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Login
        </Button>
      </Form>
    </>
  );
};

export default Login;
